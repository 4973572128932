:root {	
  --xvia-font-family: 'Inter', sans-serif;

  /* Escala de Cinzas */
  --color-grey-90: #28272c;
  --color-grey-80: #727687;
  --color-grey-70: #999eb0;
  --color-grey-40: #d6d7e0;
  
  --color-grey-10: #fdfdfd;
  /* Cores de Background */
  --color-background-primary: #f5fafe;
  --color-background-secondary: #e6f3ff;
  --color-background-dark: #0049a9;
  --color-background-subfooter: #0a2f66;
  --color-link-footer: #fdfdfd;
  --color-link-footer-hr: #fdfdfd;

  /* Cores de Surface */
  --color-surface: #ffffff;
  --color-surface-border: #d6d7e0;

  /* Cores Primárias e Secundárias */
  --color-primary: #0049a9;
  --color-primary-5: #3389F3;
  --color-primary-10: #0049a91a;
  --color-primary-50: #0049a980;
  --color-primary-hover: #0a2f66;
  --color-primary-hover-50: #0a2f6680;
  --color-secondary: #8ec63f;
  --color-secondary-10: #8ec63f1a;
  --color-secondary-hover: #7d3000;

  /* Cores de Feedback */
  --color-error: #ac0000;
  --color-error-10: #ac00001a;
  --color-error-hover: #560303;
  --color-success: #0b9d21;
  --color-success-hover: #0c5517;
  --color-warning: #0b9d21;
  --color-warning-hover: #0c5517;

  /* Cores para elementos desativados */
  --color-disabled-elements: #c0c1ce;
  --color-disabled-fonts: #999ba6;
  --color-disabled-fonts-darken: #727687;

  --color-link: #727687;
  --color-background-link-contrast: #ffffff00;
  --color-border-contrast: #fff;

  --color-button-background-default-contrast: var(--color-primary);

  /* Títulos */
  --font-title-h1: 32px;
  --line-height-title-h1: 42px;
  --letter-spacing-title-h1: 1px;
  --color-title-h1: var(--color-grey-90);

  --font-title-h2: 20px;
  --line-height-title-h2: 28px;
  --letter-spacing-title-h2: 0px;
  --color-title-h2: var(--color-grey-90);

  /* Subtítulos */
  --font-subtitle-1: 18px;
  --line-height-subtitle-1: 26px;
  --letter-spacing-subtitle-1: 0px;
  --color-subtitle-1: var(--color-grey-80);

  --font-subtitle-2: 16px;
  --line-height-subtitle-2: 22px;
  --letter-spacing-subtitle-2: 0px;
  --color-subtitle-2: var(--color-grey-80);

  --font-subtitle-3: 13px;
  --line-height-subtitle-3: 18px;
  --letter-spacing-subtitle-3: 0px;
  --color-subtitle-3: var(--color-grey-80);

  /* Corpo do texto */
  --font-body-1: 16px;
  --line-height-body-1: 24px;
  --letter-spacing-body-1: 0.1px;
  --paragraph-spacing-body-1: 20px;
  --color-body-1: var(--color-grey-80);

  --font-body-2: 13px;
  --line-height-body-2: 18px;
  --letter-spacing-body-2: 0px;
  --paragraph-spacing-body-2: 5px;
  --color-body-2: var(--color-grey-70);
  
  --font-body-3: 12px;

  /* Componentes */
  --font-button: 14px;
  --line-height-button: 20px;
  --letter-spacing-button: 0.1px;
  --color-button: var(--color-primary);

  --font-menu-item: 16px;
  --line-height-menu-item: 26px;
  --letter-spacing-menu-item: 0px;
  --color-menu-item: var(--color-grey-80);

  --font-empty-text: 16px;
  --line-height-empty-text: 26px;
  --letter-spacing-empty-text: 0px;
  --color-empty-text: var(--color-grey-80);

  --font-search-input: 14px;
  --font-search-input-header: 16px;

  --padding-top-card-service: 24px;

  /* ======== Alto Contraste ======== */
  --color-link: #727687;
  --color-background-link-contrast: #ffffff00;
  --color-border-contrast: #fff;
  
  
  /* Alto Contraste - Button*/
  --color-button-primary-hover: var(--color-primary-hover);
  --color-button-primary: var(--color-primary);
  --color-button-text-contrast: var(--color-surface);
  --color-button-inactive-text: #000;
  --color-button-inactive: #C0C1CE;
  
  --color-background-button-primary-contrast: #0049a9;
  --color-background-button-primary-outlined: var(--color-surface);
  --color-background-button-primary-outlined-border: 1px solid #0049a9;
  --color-background-button-primary-outlined-hover: #0049a91a;
  --color-button-text-primary-outlined: var(--color-primary);
  
  /* Alto Contraste - header Input search*/
  --color-header-input-background: #fff;
  --color-header-input-border: 2px solid transparent;
  --color-header-input-text: #000;
  --color-header-input-icon: #000;

  
  /* Alto Contraste - Input*/
  --color-input-background: #fdfdfd;
  --color-input-border: 2px solid #d6d7e0;
  --color-input-outlined-border: 1.5px solid #D6D7E0;
  --color-input-text: #000;
  --color-input-text-placeholder: #fff;
  --color-background-switch-checked: #0049a9;
  --color-input-outlined-background: transparent;
  
  /* Alto Contraste - Text*/
  --xvia-color-title-contrast: #fdfdfd;
  --color-title-contrast: var(--color-grey-90);
  --color-text-contrast: var(--color-grey-10);

  --color-text-contrast-90: var(--color-grey-90);
  --color-small-text-contrast: var(--color-grey-90);
  
  /* Alto Contraste - Link*/
  --color-link-header: #28272C;
  
  /* Alto Contraste - Card*/
  --color-background-service-card-contrast: #fff;
  --color-background-service-card-contrast-border: 2px solid transparent;
  --color-background-card-contrast: #d6d7e0;
  --color-background-card: #fff;
  --color-text-card-contrast: #28272c;
  --color-text-side-filter-active-contrast: #0049a9;
  --color-text-side-filter-contrast: #28272c;
  --color-link-card-contrast: #0049a9;
  --color-title-card-small-mark-contrast: #727687;

  /* Header detail */
  --color-gradient-primary: var(--color-grey-40);

  /* Breadcrumb */
  --color-breadcrumb: var(--color-grey-80);

  /* Select input */
  --color-select-input-options-hover: var(--color-primary);

  /* Card accordion secretariats and bodies */
  --color-card-accordion-subtitle: var(--color-grey-80);
  --color-card-accordion-item: var(--color-grey-80);
  --color-card-accordion-arrow: var(--color-primary);
  
  /* Governor page */
  --color-card-governor: var(--color-grey-10);
  --color-card-governor-text: var(--color-grey-80);
  --color-governor-text: var(--color-grey-80);
  
  --color-text-service-card-contrast: #28272c;

  --color-link-contrast: var(--color-link);
  --color-link-contrast-hover: #0049A9;
  --color-background-link-contrast-hover: transparent;
  --color-link-contrast-active: #0049A9;
  --color-link-service-card-contrast: #fff;

  --xvia-header-spacing-active-contrast: #FDFDFD !important;
  
  --xvia-header-icon-search-contrast: #28272C;
  --xvia-header-icon-search-contrast-hover: #0049A9;

  --color-background-header: #fff;

  --xvia-home-active-contrast: '';
  --color-button-background-primary: var(--color-primary);

  --color-button-background-top-bar: #0A2F66;
  --color-link-top-bar: #FDFDFD;

  --color-button-active: #0049a91a;

  --color-background-dot: #fdfdfd;
  --color-background-dot-active: #0049a9;
  --color-button-background-fill-arrow: #fff;

  --color-button-background-arrow: transparent;
  --color-button-border-arrow: 2px solid #0049a9;
  --color-icon-arrow: #0049a9;
  
  --color-icon-arrow-hover: #fff;
  --color-button-border-arrow-hover: 2px solid #0049a9;
  --color-button-background-arrow-hover: #0049a9;

  --color-number-pagination: #999eb0;
  --color-number-pagination-active: #0049a9;

  --color-number-pagination-hover: #0049a9;
  --color-background-number-pagination-hover: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}