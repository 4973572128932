/*------------------------------------------------------------------------------
  Header
-------------------------------------------------------------------------------*/

.xvia-header-area {
  box-shadow: 0px 4px 24px 0px #00000026;
  background-color: var(--color-background-header);
}

.xvia-container-search-input {
  width: 100%;
  height: 50px;
  background: var(--color-header-input-background);
  padding: 0;
  border-radius: 8px;
  display: flex;
  border: var(--color-header-input-border);
  color: var(--color-header-input-text);
}

.xvia-container-search-input input::placeholder {
  color: var(--color-header-input-text);
}

.xvia-container-search-input input {
  background: none;
  color: var(--color-header-input-text);
}

.xvia-search-input__block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.xvia-search-input__inputContainer {
  display: flex;
  align-items: center;
  color: var(--color-header-input-text);
  width: 100%;

  svg {
    margin: 0 5px 0 20px;
    color: var(--color-header-input-icon);
  }
}

@media (max-width: 575px) {
  .xvia-search-input__inputContainer {
    & > svg,
    & > span {
      display: none;
    }
  }
}

.xvia-search-input {
  background: rgb(255 255 255 / 30%);
  padding: 0;
  border-radius: 5px;
  display: flex;
}

.xvia-search-input__input {
  width: 100%;
  padding-left: 10px;
  font-size: var(--font-search-input-header);
  color: #28272C;
  background: none;
  border: none;
}

@media (max-width: 575px) {
  .xvia-search-input__input {
    font-size: 14px;
  }
}

.xvia-search-input__button {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}

.xvia-search-input__button-wrapper {
  height: 44px;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  border-radius: 8px;
  padding: 0 5px;

  button {
    background-color: var(--color-button-primary);
    border: none;
    padding: 6px 24px;
    border-radius: 8px;
    color: var(--color-button-text-contrast);
    cursor: pointer;    
    transition: all 0.3s ease-in-out 0s;
  }

  button:hover {
    background-color: var(--color-button-primary-hover);
  }
}

.xvia-search-input__button-search {
  padding: 5px 5px 0 5px !important;
}

.xvia-home-search__input {
  max-width: 100%;
  width: 100%;
}

.xvia-home-search__input input:focus {
  outline: none;
}

.xvia-home-search__input input::placeholder {
  color: #28272C;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .xvia-home-search .xvia-home-search__links {
    display: inline-flex;
  }

  .xvia-home-search__links {
    max-width: 66%;
    margin: auto;
  }
}

@media (min-width: 768px) {
  div.xvia-search-input__button-wrapper {
    flex: 0 0 100px;
  }
}

.xvia-header-spacing,
.xvia-header-topbar {
  padding: 10px 285px;
}

.app-bar-container {
  padding: 0 285px;
}

.xvia-header-spacing {
  width: 100% ;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  background-color: 'var(--xvia-header-spacing-active-contrast)';
  transition: all 0.5s ease-in-out 0s;
}

.xvia-header-topbar {
  display: flex;
  flex-direction: row;
  background-color: #143971;

  a {
    outline: none;
    text-decoration: none;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
}

.xvia-header-info {
  display: inline-block;
  font-size: 14px;
  margin-right: 28px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  span {
    font-weight: 700;
    color: #fff;
  }

  a {
    color: #fff;
    display: flex;
    align-items: center;
  }
}

.xvia-header-container {
  display: flex;
  width: 100%;

  svg {
    margin: 0 8px 0 0;
  }
}

.xvia-header-separator-links {
  display: flex;
  align-items: center;
}

.xvia-header-quick-links {
  text-align: right;
  margin: 0;

  a {
    display: inline-block;
    margin-left: 15px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.9);
  }
}

.xvia-header-links {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
}

@media (max-width: 575px) {
  .xvia-header-quick-links {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .xvia-header-links {
    display: none;
  }
}

@media (max-width: 767px) {
  .xvia-header-links svg {
    display: none;
  }
}

.xvia-header-elements {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 15px;
  color: var(--color-link-contrast),
}

@media (max-width: 575px) {
  .xvia-header-elements {
    display: flex;
  }
}

@media (max-width: 767px) {
  .xvia-header-elements {
    display: flex;
    gap: 8px;
    padding-left: 0;
  }

  .xvia-home-search__input {
    max-width: 100%;
  }
}

.xvia-header-btn {
  button {
    display: flex;
    align-items: center;
    color: var(--color-button-text-contrast);
    padding: 8px 16px;
    background: var(--color-button-primary);
    border-radius: 8px;
    font-weight: 700;
    outline: none;
    text-decoration: none;
    transition: all 0.3s linear 0s;
    border: none;
    cursor: pointer;

    svg {
      margin: 0 10px 0 0;
    }
  }

  button:hover {
    background: var(--color-button-primary-hover);
  }

  &__inactive {
    button {
      color: var(--color-button-inactive-text);
      background: var(--color-button-inactive);
    }
  }

  &__loading {
    button {
      opacity: 0.2;
    }
  }

  &__width-Full {
    button {
      justify-content: center;
      text-align: center;
      width: 100% !important;
    }
  }
}

.xvia-header-user-avatar {
  cursor: pointer;
}

.xvia-home-search__separator {
  display: flex;
  align-items: center;
  color: #fff;
  margin: 0 30px;
}

@media (max-width: 575px) {
  .xvia-home-search__separator {
    margin: 5px 0;
  }
}

.xvia-header-user-notifications {
  position: relative;
  width: 52px;
  margin-right: 0.8em;

  &__icon {
    width: 32px;
    margin-right: 8px;
  }

  &__unread {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 6.5px;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0px;
    top: 0px;
    background: #0048b6;
    border-radius: 64px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
}

/* Small */
@media (max-width: 767px) {
  .xvia-header-topbar,
  .xvia-header-spacing {
    padding: 10px 15px;
  }

  .app-bar-container {
    padding: 0 15px;
  }

  .xvia-header-spacing {
    display: flex;  
    transition: all 0.5s ease-in-out 0s;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-header-topbar,
  .xvia-header-spacing {
    padding: 10px 15px;
  }

  .app-bar-container {
    padding: 0 15px;
  }
}

@media (min-width: 992px) and (max-width: 1501px) {
  .xvia-header-topbar,
  .xvia-header-spacing {
    padding: 10px 80px;
  }

  .app-bar-container {
    padding: 0 80px;
  }
}

/* Large */
@media (min-width: 1501px) and (max-width: 1800px) {
  .xvia-header-topbar,
  .xvia-header-spacing {
    padding: 10px 100px;
  }

  .app-bar-container {
    padding: 0 100px;
  }
}
